import Vue from "vue";
import VueRouter from "vue-router";

import { canNavigate } from "@/libs/acl/routeProtection";
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from "@/auth/utils";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/home",
      name: "home",
      meta: {
        action: "read",
        resource: "home",
      },
      component: () => import("@/views/Home.vue"),
    },
    {
      path: "/",
      name: "home",
      meta: {
        action: "read",
        resource: "home",
      },
      component: () => import("@/views/Home.vue"),
    },
    {
      path: "/settings",
      name: "settings",
      meta: {
        action: "read",
        resource: "settings",
      },
      component: () => import("@/views/Settings.vue"),
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        layout: "full",
        resource: "Auth",
        redirectIfLoggedIn: true,
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
        action: "hata",
        resource: "error-pages",
      },
    },
    {
      path: "/misc-not-authorized",
      name: "misc-not-authorized",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
        action: "hata",
        resource: "error-pages",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
    // Admin Sayfaları
    {
      path: "/Admin/ModulEkle",
      name: "ModulEkle",
      meta: {
        action: "read",
        resource: "ModulEkle",
      },
      component: () => import("@/views/adminPages/modulekle.vue"),
    },
    {
      path: "/Admin/SayfaEkle",
      name: "SayfaEkle",
      meta: {
        action: "read",
        resource: "SayfaEkle",
      },
      component: () => import("@/views/adminPages/sayfaEkle.vue"),
    },
    {
      path: "/Admin/RolEkle",
      name: "RolEkle",
      meta: {
        action: "read",
        resource: "RolEkle",
      },
      component: () => import("@/views/adminPages/rolekle.vue"),
    },
    {
      path: "/Admin/RolYetkiYonetimi",
      name: "RolYetkiYonetimi",
      meta: {
        action: "read",
        resource: "RolYetkiYonetimi",
      },
      component: () => import("@/views/adminPages/rolYetkiYonetimi.vue"),
    },
    {
      path: "/Admin/SorguEkle",
      name: "SorguEkle",
      meta: {
        action: "read",
        resource: "SorguEkle",
      },
      component: () => import("@/views/adminPages/sorguEkle.vue"),
    },
    {
      path: "/Admin/Gorevler",
      name: "Gorevler",
      meta: {
        action: "read",
        resource: "Gorevler",
      },
      component: () => import("@/views/adminPages/gorevler.vue"),
    },

    //Raporlar

    {
      path: "/raporlar/RandevuRaporlari",
      name: "RandevuRaporlari",
      meta: {
        action: "read",
        resource: "RandevuRaporlari",
      },
      component: () => import("@/views/raporlar/RandevuRaporlari.vue"),
    },
    {
      path: "/raporlar/TasarimSayfasi",
      name: "TasarimSayfasi",
      meta: {
        action: "read",
        resource: "TasarimSayfasi",
      },
      component: () => import("@/views/raporlar/Tasarim.vue"),
    },
  ],
});

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn();
  if (!canNavigate(to)) {
    if (!isLoggedIn) {
      localStorage.setItem("backPage", window.location.href);
      return next({ name: "login" });
    }
    return next({ name: "misc-not-authorized" });
  }

  if (to.meta.redirectIfLoggedIn !== undefined) {
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
      const userData = getUserData();
      next(getHomeRouteForLoggedInUser(userData ? userData.role : null));
    }
  }

  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
